import "./App.css";
import React, { useEffect } from "react";
import Landing from "./pages/landing";
import Legal from "./pages/legal";
import Marketplace from "./pages/marketplace";
import Comparison from "./pages/comparison";
import OverallPortfoilo from "./pages/Dashboard/OverallPortfoilo";
// import TestPage from "./pages/test/TestPage";
import CardPage from "./pages/CardPage";
import PropertyInfoPage from "./pages/PropertyInfoPage";
import LoginPage from "./pages/Login";

import { Routes, Route, useParams } from "react-router-dom";
// import RegistrationPage from "./pages/registration-page/registration-page";
import RegistrationAgentPage from "./pages/registration-page/registration-agent-page";
import TestLogedin from "./pages/test-logedin";
import PasswordResetPage from "./pages/reset-password/password-reset-page";
import SeedRoundPage from "./pages/seed-round-page";
import Blog from "./pages/Blog/blog";
import Links from "./pages/Links";
// import LoginRedirect from "./pages/test/LoginRedirect";
import BenefitsPage from "./pages/Benefits";
import ProprtFounderPage from "./pages/proprt-founder";
import Explainer from "./pages/Explainer";
import FaqPage from "./pages/Faq";
import { Helmet } from "react-helmet";
import PayPage from "./components/pay";
import RegisterPage from "./pages/Register/";
import DashboardPage from "./pages/Dashboard";
import ContactRonnie from "./pages/Contacts/Ronnie";
import IcoPage from "./pages/Ico";

function App() {
  let { userId } = useParams();
  return (
    <>
      <Helmet>
        <title>PROPRT.io</title>
        <meta
          name="description"
          content="PROPRT is a real estate investment platform that allows you to invest in real estate with as little as $200."
        />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/comparison" element={<Comparison />} />
        <Route path="/marketplace" element={<Marketplace />} />
        {/* <Route path="/faq" element={<FaqPage />} /> */}
        <Route path="/dashboard/portfolio" element={<OverallPortfoilo />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        {/* <Route path="/web3" element={<TestPage />} /> */}
        <Route path="/card" element={<CardPage />} />
        <Route path="/properties/minnie-1" element={<PropertyInfoPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register-agent" element={<RegistrationAgentPage />} />
        <Route path="/test-login" element={<TestLogedin />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />
        <Route path="/seed-round" element={<SeedRoundPage />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/proprt_founder/266" element={<ProprtFounderCardFlip />} /> */}
        <Route path="/links" element={<Links />} />
        <Route path="/contact/ronnie" element={<ContactRonnie />} />
        <Route path="/promo" element={<Redirect url={"/links"} />} />
        {/* <Route
          path="/api/connect/:providerName/redirect"
          element={LoginRedirect()}
        /> */}
        <Route path="/benefits" element={<BenefitsPage />} />
        <Route path="/proprt-founder" element={<ProprtFounderPage />} />
        <Route path="/explain" element={<Explainer />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/pay" element={<PayPage />} />
        <Route path="/ico" element={<IcoPage />} />
      </Routes>
    </>
  );
}

// property-info

export default App;

const Redirect = (props) => {
  const { url } = props;
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return (
    <body className="bg-[#2276C9] h-full">
      <h5 className="text-center">Redirecting...</h5>
    </body>
  );
};
