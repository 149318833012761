import React, { useState } from "react";

import Popup from "reactjs-popup";
import { useMediaQuery } from "react-responsive";

import illustration_click from "../img/illustration/click_.png";
import illustration_own from "../img/illustration/own_.png";
import illustration_sell from "../img/illustration/sell_.png";
import illustartion_track from "../img/illustration/track_.png";
import botomHero from "../img/Marketplace/houses_bg02.jpg";

export default function How() {
  const [isShown01, setIsShown01] = useState(false);
  const [isShown02, setIsShown02] = useState(false);
  const [isShown03, setIsShown03] = useState(false);
  const [isShown04, setIsShown04] = useState(false);

  const isPc = useMediaQuery({ query: "(min-width: 1225px)" });

  function popUp(title, text) {
    return (
      <Popup
        open={true}
        modal
        onClose={() => {
          setIsShown01(false);
          setIsShown02(false);
          setIsShown03(false);
          setIsShown04(false);
        }}
      >
        <div
          className="container rounded shadow-lg p-5 pb-4 text-center"
          style={
            isPc
              ? {
                  width: "40%",
                  backgroundImage: `url(${botomHero})`,
                  backgroundSize: "100%",
                }
              : {
                  width: "auto",
                  backgroundImage: `url(${botomHero})`,
                  backgroundSize: "100%",
                }
          }
        >
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "bold",
              color: "#4AB29D",
            }}
          >
            {title}
          </h3>
          <div className="pt-2">
            <p style={{ fontFamily: "Montserrat" }}>{text}</p>
          </div>
        </div>
      </Popup>
    );
  }

  return (
    <React.Fragment>
      {isShown01 &&
        popUp(
          "Select",
          "Choose from a range of investment grade properties  with different profiles to diversify your portfolio. Properties are carefully screened by our team of experts to maximise income and capital gains."
        )}
      {isShown02 &&
        popUp(
          "Buy",
          "Buy a unique piece of a specific property using your web3 wallet or fiat currency. Secure holdings in as many properties as you like."
        )}
      {isShown03 &&
        popUp(
          "Unique Token",
          "Your PROPRT Token is uniquely identified and secured on the Ethereum blockchain. You can store your PROPRT in your web3 wallet, your PROPRT.io dashboard, or keep it off-line (cold storage)."
        )}
      {isShown04 &&
        popUp(
          "Sell",
          "Control how and when you sell your PROPRT holdings, set the timing and minimum price. Or simply hold and receive your share of rental income."
        )}

      <div className="my-40 md:my-60">
        <div className="container text-center">
          <h1
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
            className="text-4xl text-[#0C2741] mb-10"
          >
            Property Investing Simplified
          </h1>

          <div className="grid grid-cols1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-20 ">
            <div className=" mt-10 md:mt-0" style={{}}>
              <img
                className=" headerIcons  mx-auto"
                src={illustration_click}
                alt="logo"
                onClick={() => {
                  setIsShown01(true);
                }}
              />
              <p className="mt-8 mb-2 text-[#0C2741] font-semibold text-xl">
                Select
              </p>
              <p className="text-md text-[#0C2741]">Choose pieces of property from across the globe</p>
            </div>
            <div className=" mt-10 md:mt-0">
              <img
                className=" headerIcons  mx-auto"
                src={illustration_own}
                alt="logo"
                onClick={() => {
                  setIsShown02(true);
                }}
              />
               <p className="mt-8 mb-2 text-[#0C2741] font-semibold text-xl">
                Buy
              </p>
              <p className="text-md text-[#0C2741]">Purchase with credit card, paypal or web3 wallet</p>
            </div>
            <div className=" mt-10 md:mt-0">
              <img
                className=" headerIcons  mx-auto"
                src={illustartion_track}
                alt="logo"
                onClick={() => {
                  setIsShown03(true);
                }}
              />
             <p className="mt-8 mb-2 text-[#0C2741] font-semibold text-xl">
                Own
              </p>
              <p className="text-md text-[#0C2741]">Your unique digital asset is secured in your wallet</p>
            </div>
            <div className=" mt-10 md:mt-0">
              <img
                className=" headerIcons  mx-auto"
                src={illustration_sell}
                alt="logo"
                onClick={() => {
                  setIsShown04(true);
                }}
              />
              <p className="mt-8 mb-2 text-[#0C2741] font-semibold text-xl">
                Earn
              </p>
              <p className="text-md text-[#0C2741]">Receive rental income automatically</p>
            </div>
          </div>
          <button
            type="button"
            className="headerButtonBlue bg-[#2276c9] mt-5 mx-auto"
            onClick={() => {
              window.open("/benefits", "_self");
            }}
          >
            How it works
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
