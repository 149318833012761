import React from "react";
import vision1 from "./../img/vision1.png";
import vision2 from "./../img/vision2.png";

export default function Vision() {
  return (
    <div className="bg-white text-center my-40 mx-40">
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
      <div className="text-[#888a8b] text-[1.6rem]">Our Vision</div>
      <div className="text-[#2d83cb] text-[40px] font-bold">
        Fractional Property on Blockchain
      </div>
      <div className="flex justify-center gap-16 my-8">
        <img src={vision1} alt={"Vision1"} />
        <img src={vision2} alt={"Vision2"} />
      </div>
      <div className="text-[#888a8b] text-[20px] w-1/2 mx-auto">
        <p className="py-3">
          Our vision is to become the online platform for digital property
          ownership.
        </p>
        <p className="py-3">
          We fractionalise property, offer PROPRT ownership secured on the
          Ethereum blockchain and provide a marketplace for buying and selling.
        </p>
        <p className="py-3">
          We also offer a “Governance Token” – Real Estate (REST) Tokens which
          represents the entire ecosystem value. This ICO is for REST tokens.
        </p>
        <p className="py-3">
          Taking that first step onto the property ladder is becoming
          increasingly difficult. Purchasing property today requires either a
          large deposit or an established track record as an investor.
        </p>
        <p className="py-3">
          Our platform simplifies the way we think about buying and selling
          property, brings liquidity to this asset class and secures ownership
          via the Ethereum Network.
        </p>
      </div>
    </div>
  );
}
