import React, { useState } from "react";
import Navbar from "../components/Navbar";
import hero from "../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../img/Marketplace/houses_bg02.jpg";
import Footer from "../components/Footer";
import MailingRegistration from "../components/MailingRegistration";

import informationIllustration from "../img/Marketplace/information.png";
import searchIlllustration from "../img/Marketplace/search.png";

import il_property_01 from "../components/img/39_Minnie_St_Ext_View_02_Low_Res_002.jpg";
import il_property_comingsoon from "../components/img/39_Minnie_St_Ext_View_01_Low_Res_002.png";
import Popup from "reactjs-popup";
import { useMediaQuery } from "react-responsive";

export default function Marketplace() {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      <Navbar />
      <section
        style={{
          paddingTop: "10rem",
          paddingBottom: "5rem",
          minHeight: "100rem",
          backgroundImage: `url(${hero}), url(${botomHero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top, bottom",
        }}
      >
        <div className="text-center">
          <h1 className="header-title">Marketplace</h1>
        </div>
        <div
          className="container justify-content-center mt-5"
          style={{ paddingLeft: "2%", paddingRight: "2%" }}
        >
          <div className="row text-center marketplace-long-card justify-content-center mx-auto">
            <div className="col-4">
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    width: "90%",
                    backgroundColor: "#4AB29D",
                    borderColor: "#4AB29D",
                    fontSize: isMobile ? "10px" : "16px",
                  }}
                >
                  Location
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-light"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li>
                    <a className="dropdown-item active" href="#">
                      NSW
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      QLD
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      WA
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      NT
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      VIC
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      SA
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      TAS
                    </a>
                  </li>
                  {/* <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#">Separated link</a></li> */}
                </ul>
              </div>
            </div>
            <div className="col-4">
              <div className="dropdown mx-auto">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    width: "90%",
                    backgroundColor: "#4AB29D",
                    borderColor: "#4AB29D",
                    fontSize: isMobile ? "10px" : "16px",
                  }}
                >
                  Yield
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-light"
                  aria-labelledby=""
                >
                  <li>
                    <a className="dropdown-item active" href="#">
                      Up to 5%
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      5 to 7%
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      7 to 9%
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      9% +
                    </a>
                  </li>
                  {/* <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#">Separated link</a></li> */}
                </ul>
              </div>
            </div>
            <div className="col-4">
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle marketplace-btn-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    width: "90%",
                    backgroundColor: "#4AB29D",
                    borderColor: "#4AB29D",
                    fontSize: isMobile ? "10px" : "16px",
                  }}
                >
                  Token Price
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-light"
                  aria-labelledby=""
                >
                  <li>
                    <a className="dropdown-item active" href="#">
                      Up to $4
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      $4 - $6
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      $6 to $8
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      $8 to $10
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      $10 +
                    </a>
                  </li>
                  {/* <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#">Separated link</a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="justify-content-center mt-5 container"
          style={{ paddingLeft: "2%", paddingRight: "2%" }}
        >
          <div
            className=" row text-center marketplace-long-card align-items-center justify-content-center pe-5 ps-5 pt-3 pb-3 mx-auto"
            style={{ width: "100%" }}
          >
            <div className="col-md" style={{}}>
              <img
                alt=""
                src={informationIllustration}
                style={{ width: "50%" }}
              />
            </div>
            <div className="col-md-7 p-0 m-0" style={{ height: "200%" }}>
              <p
                style={{
                  fontSize: "45px",
                  color: "#2276C9",
                  display: "flex",
                  fontWeight: 400,
                }}
                className="p-0 m-0 content-center"
              >
                Need more information?
              </p>
            </div>
            <div className="col-md">
              <button
                className="headerButtonBlue"
                type="button"
                aria-expanded="false"
                style={{ width: "100%" }}
                onClick={() => {
                  window.open("/faq", "_self");
                }}
              >
                Read Our FAQs
              </button>
            </div>
          </div>
        </div>
        <div
          className="container row mx-auto pe-0 ps-0 "
          style={{ paddingTop: "4rem", width: "100vw" }}
        >
          {/* {cardMockupList()} */}
          {properties.map((property) => CardMockup(property))}
        </div>
        <div
          className="justify-content-center mt-5 container"
          style={{ paddingLeft: "2%", paddingRight: "2%" }}
        >
          <div className="row text-center marketplace-long-card align-items-center justify-content-center pe-5 ps-5 pt-3 pb-3 mx-auto">
            <div className="col-md" style={{}}>
              <img alt="" src={searchIlllustration} style={{ width: "50%" }} />
            </div>
            <div className="col-md-8 p-0 m-0" style={{ height: "200%" }}>
              <p
                style={{
                  fontSize: "45px",
                  color: "#2276C9",
                  display: "flex",
                  fontWeight: 400,
                }}
                className="p-0 m-0 content-center"
              >
                Don't know which to choose?
              </p>
            </div>
            <div className="col-md">
              <button
                className="headerButtonBlue"
                type="button"
                aria-expanded="false"
                style={{ width: "100%" }}
                onClick={() => (window.location = "mailto:hello@proprt.io")}
              >
                hello@proprt.io
              </button>
            </div>
          </div>
        </div>
      </section>
      <MailingRegistration />
      <Footer />
    </>
  );
}

const properties = [
  {
    id: "Minnie-1",
    location: "39 Minnie St QLD",
    picture: il_property_01,
    _yield: "4.7",
    gain: "6.9",
    price: "216",
  },
  {
    id: "Minnie-2",
    location: "Southport QLD",
    picture: il_property_comingsoon,
    _yield: "4.7",
    gain: "6.9",
    price: "220",
  },
  {
    id: "Minnie-3",
    location: "Southport QLD",
    picture: il_property_comingsoon,
    _yield: "4.7",
    gain: "6.9",
    price: "245",
  },
  {
    id: "Bris-3",
    location: "Brisbane QLD",
    picture: il_property_comingsoon,
    _yield: "5.0",
    gain: "7.1",
    price: "304",
  },
];

function CardMockup({ id, location, picture, _yield, gain, price }) {
  const [data, setData] = useState({
    email: "",
    name: "",
    value: "",
  });

  const [showModal, setShowModal] = useState(false);

  function popUp() {
    return (
      <Popup
        open={true}
        modal
        onClose={() => {
          setShowModal(false);
        }}
      >
        <form
          className="container bg-white rounded shadow-lg p-5 text-center"
          style={{
            backgroundSize: "100%",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
          onSubmit={async (e) => {
            e.preventDefault();
            if (data.email === "" || data.name === "" || data.value === "") {
              alert("Please fill everything.");
            } else {
              await fetch(
                "https://assets.mailerlite.com/jsonp/1318/forms/55423493200676580/subscribe",
                {
                  method: "POST",
                  body: JSON.stringify({
                    fields: {
                      email: data.email,
                      name: data.name,
                      total: data.value,
                    },
                  }),
                  headers: {
                    // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => res.json());
              alert("Thank you for your interest. We will contact you soon.");
            }
          }}
        >
          <h1
            style={{
              fontFamily: "Montserrat",
              color: "#4AB29D",
              fontWeight: "bold",
              maxWidth: "30rem",
            }}
          >
            Secure your piece of property
          </h1>
          <p>Please fill out this form below, and we will contact you soon.</p>
          <input
            className="login-input"
            type="email"
            placeholder="Email"
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
          />
          <br />
          <input
            className="login-input"
            type="text"
            placeholder="Name"
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
          <br />
          <input
            className="login-input"
            type="text"
            placeholder="How much would you like to invest?"
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setData({ ...data, value: e.target.value });
            }}
          />

          <div className="mx-auto pt-5">
            <button
              type="submit"
              style={{ backgroundColor: "#4AB29D", maxWidth: "10rem" }}
              className="btn-primary mx-auto custom-btn"
            >
              Register
            </button>
          </div>
        </form>
      </Popup>
    );
  }

  return (
    <div className="col-xl-4 p-0" style={{ textAlign: "center" }}>
      {showModal && popUp()}
      <div className="market-card bootstrap-off mx-auto">
        <div
          className="row bootstrap-off"
          style={{
            paddingTop: "0px",
            backgroundColor: "#3489DB",
            width: "100%",
            height: "15%",
            borderRadius: "25px 25px 0px 0px",
          }}
        >
          <div className="col-10 mt-2 text-start">
            <p
              style={{
                margin: 0,
                padding: 0,
                color: "white",
                fontWeight: "400",
              }}
            >
              {id}
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                color: "white",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {location}
            </p>
          </div>
          <div
            className="col bootstrap-off"
            style={{
              backgroundColor: "#4AB29D",
              height: "100%",
              marginTop: 0,
              borderRadius: "0px 25px 0px 0px",
            }}
          >
            <p className="mx-auto" style={{ color: "white", marginTop: "50%" }}>
              NEW
            </p>
          </div>
        </div>
        <div
          className="bootstrap-off text-center center"
          style={{
            alignContent: "center",
            width: "100%",
            height: "30%",
          }}
          onClick={() => {
            if (id === "Minnie-1") {
              window.open("/properties/minnie-1");
            } else {
              alert("This property is coming soon. Stay tuned.");
            }
          }}
        >
          <img
            src={picture}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="..."
          />
        </div>
        <div
          className="bootstrap-off"
          style={{
            alignContent: "center",
            backgroundColor: "white",
            height: "42%",
          }}
        >
          <div
            className="row text-center"
            style={{ margin: 0, padding: 0, marginTop: "3px" }}
          >
            <div
              className="col-6"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                Rental Yield
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 13.5,
                }}
              >
                (NET)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 30,
                }}
              >
                {_yield}%
              </p>
            </div>
            <div className="col-6" style={{ margin: 0, padding: 0 }}>
              <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                Capital Gain
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 13.5,
                }}
              >
                (HISTORIC)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 30,
                }}
              >
                {gain}%
              </p>
            </div>
          </div>
          <div
            className="text-center"
            style={{ margin: 0, padding: 0, marginTop: "20px" }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
              PROPRT Price
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontWeight: "400",
                fontSize: 13.5,
              }}
            >
              (Property Token)
            </p>
            <p
              style={{ margin: 0, padding: 0, fontWeight: "700", fontSize: 30 }}
            >
              ${price}
            </p>
          </div>
        </div>
        {/* <div className='bootstrap-off text-center' style={{'alignContent': 'center', backgroundColor: '#4AB29D', width: '100%', height: '13%', borderRadius: '0px 0px 25px 25px', paddingTop: '3.5%'}}>
                            <p style={{'fontSize': '20px', fontWeight: '600', color: 'white'}}>View Property</p>
                        </div> */}
        <div
          className="row bootstrap-off"
          style={{
            paddingTop: "0px",
            backgroundColor: "#3489DB",
            width: "100%",
            height: "15%",
            borderRadius: "0px 0px 25px 25px",
          }}
        >
          <div
            id="buyNow"
            className="col-6 text-center"
            onClick={() => {
              if (id === "Minnie-1") {
                // setShowModal(true);
                window.open("/pay");
              } else {
                setShowModal(false);
                alert("This property is coming soon. Stay tuned.");
              }
            }}
          >
            <p
              style={{
                marginTop: "15%",
                fontSize: "20px",
                fontWeight: "600",
                color: "white",
              }}
            >
              Buy Now
            </p>
          </div>
          <div
            id="viewDetail"
            className="col-6 text-center"
            onClick={() => {
              if (id === "Minnie-1") {
                window.open("/properties/minnie-1");
              } else {
                alert("This property is coming soon. Stay tuned.");
              }
            }}
          >
            <p
              style={{
                marginTop: "15%",
                fontSize: "20px",
                fontWeight: "600",
                color: "white",
              }}
            >
              View
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
