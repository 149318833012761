import React from "react";

export default function Cta() {
  return (
    <div className="bg-white text-center my-40 mx-40">
      <div className="text-[#2d83cb] text-[40px] font-bold">
        Building a Full Ecosystem
      </div>
      <div className="flex justify-center gap-16 my-8"></div>
      <div className="text-[#888a8b] text-[20px] w-1/2 mx-auto">
        <p className="py-3 uppercase">
          OUR VISION IS TO BRING LIQUIDITY TO PROPERTY INVESTORS.
        </p>
        <p className="py-3">
          We are building an ecosystem for Property Investors to benefit from
          holding property (PROPRT) or through Real Estate (REST) tokens with an
          interest in the entire ecosystem.
        </p>
        <p className="py-3">
          The platform will grow into a DeFi platform where token holders can
          borrow, lend, stake and provide liquidity through our platform. Once
          the platform reaches maturity REST holders will be able to vote for
          the outcomes based on DA governance. With tight restrictions to ensure
          compliance with real world legal and fiduciary obligations.
        </p>
        <p className="py-3">
          Money was once backed by Golds, a tangible, finite resource. We are
          creating a currency backed by a tangible, finite resource – Property
          with Income.
        </p>
        <p className="py-3 uppercase">
          THIS ICO IS FOR REAL ESTATE (REST) TOKENS
        </p>
      </div>
      <div className="flex justify-center ">
        <div className="flex rounded-full bg-[#222427] w-1/2 p-2 mt-16">
          <input
            type="text"
            className="py-3 px-3 rounded-full w-3/5 mr-1"
            placeholder="Email"
          />
          <button
            type="button"
            className=" font-semibold  text-[#fff] bg-[#2276c9] py-3 px-3 rounded-full w-2/5 ml-1 hover:bg-[#43C1A8]"
          >
            Register Interest
          </button>
        </div>
      </div>
    </div>
  );
}
