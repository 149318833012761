import React from "react";

export default function About() {
  return (
    <div className="bg-white text-center my-40 mx-40">
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
      <div className="text-[#2d83cb] text-[40px] font-bold">
        What is PROPRT.io
      </div>
      <div className="flex justify-center gap-16 my-8">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/XvZBptrTB1g?si=nCV4mIDftxSiKcVj"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="text-[#888a8b] text-[20px] w-1/2 mx-auto">
        <p className="py-3">
          Purchase investment grade property without the hassle of giving up
          your weekends searching endlessly for the right property.
        </p>
        <p className="py-3">
          Our team of highly experienced property investors, managers and
          professionals target both yield and capital gain strategies with full
          transparency.
        </p>
        <p className="py-3">
          Our system fractionalizes the property (small portions of property),
          creates a digital record of ownership (PROPRT NFT) on the Ethereum
          Blockchain and allows you to easily buy, sell and track your property
          interests, while receiving your share of rental income.
        </p>
        <p className="py-3">
          Own a piece of the entire Ecosystem through REST tokens, receive fees
          and secure voting privileges.
        </p>
      </div>
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
    </div>
  );
}
