import React from "react";
import selection1 from "./../img/selection1.png";

export default function Selection() {
  return (
    <div className="bg-white text-center my-40 mx-40">
      <div className="text-[#2d83cb] text-[40px] font-bold">
        Property Selection
      </div>

      <div className="text-[#888a8b] text-[20px] w-1/2 mx-auto">
        <p className="py-3">
          Choose from any of our carefully curated 'investment grade'
          properties, selected to maximise both income and capital gains.
        </p>
      </div>
      <div className="flex justify-center gap-16 my-8">
        <img src={selection1} alt={"selection1"} className="w-[80%]" />
      </div>
    </div>
  );
}
