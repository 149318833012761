import React, { useState, useEffect } from "react";
import "./Faq.css";
import ellipseright from "./img/ellipseright.png";

export default function Faq() {
  const [faqs, setFaqs] = useState("");
  const url =
    " https://api1.proprt.io/api/landing-faqs?fields=id&populate[resource][fields]=title,article";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
        });
        const json = await response.json();
        setFaqs(json.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const faqList =
    faqs &&
    Array.from(faqs).map(({ attributes, id }) => {
      return (
        <React.Fragment key={id}>
          <AccordionItem
            title={attributes.resource.data.attributes.title}
            desc={attributes.resource.data.attributes.article}
            collapse={"collapse" + id}
          />
        </React.Fragment>
      );
    });

  return (
    <React.Fragment>
      <div
        className="py-40"
        style={{
          backgroundImage: `url(${ellipseright})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
          backgroundPosition: "right",
        }}
      >
        <section style={{}} className="" id="faq">
          <div className="sm:container mx-auto text-center rounded-xl xl:px-60 pt-16 pb-20 mb-20 relative">
            <h1 className="text-4xl text-[#0C2741] mb-10 font-semibold">FAQ</h1>
            <div className="accordion" id="accordionExample">
              {faqList}
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

const AccordionItem = ({ title, desc, collapse }) => {
  return (
    <React.Fragment>
      <div className="accordion-item bg-white border-b-2 shadow-2xl">
        <h2 className="accordion-header mb-0" id="headingOne">
          <button
            className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
        text-[#0C2741] font-semibold text-xl
      "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + collapse}
            aria-expanded="true"
            aria-controls={collapse}
          >
            {title}
          </button>
        </h2>
        <div
          id={collapse}
          className="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body py-4 px-5 bg-[#E7F3F8]">{desc}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
