import React from "react";
import map from "../img/map.png";

export default function Location() {
  return (
    <React.Fragment>
      <section style={{}} className="mt-5 pt-5">
        <div className="container-fluid text-center bg-[#E7F3F8] rounded-lg px-16 pt-16 pb-20 relative">
          <h1 className="text-4xl text-[#0C2741] mb-10 font-semibold">
          Stay local, invest global
          </h1>
          <p
            className="text-center mt-4 text-[#0C2741]"
            style={{ marginBottom: "0rem" }}
          >
           Stay tuned as we build out property portfolios across the globe in stable economic environments 
            <br />
            with regulatory clarity and a proven track record of adopting new technology.
          </p>
          <div className="justify-center mt-10 w-full hidden xl:flex">
            <img src={map} alt="map" className="text-center" />
          </div>
        </div>
        <div className="bg-[#E7F3F8]">
        <div className="justify-center w-full flex xl:hidden">
            <img src={map} alt="map" className="text-center" />
          </div>
        </div>
      </section>

    </React.Fragment>
  );
}
