import React from "react";
import logo from "../img/logo/proprt_logo_final.png";

import xmark from "../img/icons/xmark_comparison.png";
import checkmark from "../img/icons/checkmark_comparison.png";

import illustration_nfpt from "../img/illustration/nfpt.png";
import illustration_rest from "../img/illustration/rest_ token 050522.png";
import illustration_proprt_token from "../img/illustration/proprt_ token.png";
import { useMediaQuery } from "react-responsive";
import { FcOk, FcCancel } from "react-icons/fc";
import invest1 from "./../img/illustration/invest1.png";
import invest2 from "./../img/illustration/invest2.png";

const HeadRow = (props) => {
  return (
    <tr className="border-y-2">
      <td className="p-4 bg-[#E7F1FB] text-[#2F7ECD] font-semibold" colspan="4">
        {props.text}
      </td>
    </tr>
  );
};

const Row = (props) => {
  return (
    <tr className="border-y-2">
      <td className="p-4 font-semibold">{props.text}</td>
      <td className="p-4">
        <div className="justify-center flex">
          {checkComparison(props.traditional)}
        </div>
      </td>
      <td className="p-4">
        <div className="justify-center flex">{checkComparison(props.reit)}</div>
      </td>
      <td className="p-4">
        <div className="justify-center flex">
          {checkComparison(props.platform)}
        </div>
      </td>
    </tr>
  );
};

function checkComparison(val) {
  if (!val) {
    return <img src={xmark} alt="xmark" />;
  }

  if (val) {
    return <img src={checkmark} alt="checkmark" />;
  }
}

export default function QuickArticles() {
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  return (
    <section style={{}} className="mt-20 mb-40 md:mt-40 md:mb-80 pt-5">
      <div className="sm:container mx-auto text-center bg-[#E7F3F8] rounded-[32px] md:px-16 pt-16 pb-12 xl:pb-80 mb-20 xl:mb-96 relative">
        <h1 className="text-4xl text-[#0C2741] mb-10 font-semibold">
          Two ways to invest
        </h1>
        <p
          className="text-center mt-4 text-[#0C2741]"
          style={{ marginBottom: "0rem" }}
        >
          You can either own a unique piece of property (Digital Asset) secured
          on the blockchain
          <br /> or you can own a piece of the whole ecosystem through PROPRT
          tokens.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 text-center mt-5 ms-5 me-5 align-content-center justify-content-center gap-8 relative xl:absolute xl:top-52">
          <div className="bg-white rounded-[24px] p-12 xl:p-16 drop-shadow-2xl">
            <img
              className="cursor-pointer  mx-auto "
              src={invest1}
              alt="logo"
              onClick={() => {
                alert("Coming soon");
              }}
            />
            <h3 className="text-base xl:text-2xl text-[#0C2741] mt-5 font-semibold mb-5">
              Digital Asset
            </h3>
            <p className="text-xs xl:text-base" style={{ color: "grey" }}>
              A Digital Asset is a Non-Fungible Property Token, which means each
              token is unique. This is an evolution of the NFT, which is built
              on the Ethereum blockchain (ERC-1155). You can verify your
              ownership, secure it in your own wallet or even take it offline
              (cold storage).
            </p>
          </div>

          <div className=" bg-white rounded-[24px] p-12 xl:p-16 drop-shadow-2xl">
            <img
              className="cursor-pointer mx-auto"
              src={invest2}
              alt="logo"
              onClick={() => {
                alert("Coming soon");
              }}
            />
            <h3 className="text-base xl:text-2xl  text-[#0C2741] mt-5 font-semibold mb-5">
              PROPRT
            </h3>
            <p className="text-xs xl:text-base" style={{ color: "grey" }}>
              PROPRT tokens are standard ERC-20 tokens used to build and run the
              PROPRT.io platform. The tokens represent an ownership stake in the
              value of the entire ecosystem. Fees generated go to PROPRT token
              holders. For more information refer to the{" "}
              <a
                href="/PROPRT.io-Tokenomics-v0.4.pdf"
                style={{ color: "#2276C9" }}
              >
                tokenomics document
              </a>
            </p>
          </div>
        </div>
      </div>

      <section id="comparison-chart" className="pt-20 md:pt-60">
        <div className="text-center">
          <h1 className="text-4xl text-[#0C2741] mb-10 font-semibold">
            Comparison Chart
          </h1>
        </div>
        <div className="sm:container mx-auto row pt-5 p-5 overflow-x-auto">
          <table class="table-auto">
            <thead>
              <tr className="border-y-2 text-[#2276C9]">
                <th className="p-4 w-1/2">Features</th>
                <th className="p-4 w-1/6 text-center">Traditional Ownership</th>
                <th className="p-4 w-1/6 text-center">REIT</th>
                <th className="p-4 w-1/6 text-center">PROPRT Platform</th>
              </tr>
            </thead>
            <tbody>
              <HeadRow text={"Blockchain"} />
              <Row
                text={"Low Fees"}
                traditional={false}
                reit={false}
                platform={true}
              />
              <Row
                text={"Buy with crypto"}
                traditional={false}
                reit={false}
                platform={true}
              />
              <Row
                text={"Blockchain secured"}
                traditional={false}
                reit={false}
                platform={true}
              />
              <Row
                text={"Low exit fees"}
                traditional={false}
                reit={false}
                platform={true}
              />
              <Row
                text={"Reduce third party risk"}
                traditional={false}
                reit={false}
                platform={true}
              />
              <Row
                text={"Fund Immediately accessible"}
                traditional={false}
                reit={false}
                platform={true}
              />
              <HeadRow text={"Fractional Property"} />
              <Row
                text={"Reduce third party risk"}
                traditional={false}
                reit={true}
                platform={true}
              />
              <Row
                text={"Hassle - free"}
                traditional={false}
                reit={true}
                platform={true}
              />
              <Row
                text={"Liquidity (Easy to sell)"}
                traditional={false}
                reit={true}
                platform={true}
              />
              <Row
                text={"NQ mortgage required"}
                traditional={false}
                reit={true}
                platform={true}
              />
              <Row
                text={"Build portfolio incrementally"}
                traditional={false}
                reit={true}
                platform={true}
              />
              <Row
                text={"Less risk*"}
                traditional={false}
                reit={true}
                platform={true}
              />
              <Row
                text={"Could live in property"}
                traditional={false}
                reit={true}
                platform={true}
              />
              {/* <HeadRow text={"Property"} />
              <Row text={"Transparency"} traditional={true} reit={false} platform={true}/>
              <Row text={"Control property selection"} traditional={true} reit={false} platform={true}/>
              <Row text={"Residental property focus"} traditional={true} reit={false} platform={true}/>
              <Row text={"Control of sale price"} traditional={true} reit={false} platform={true}/>
              <Row text={"Ability to learn property investing"} traditional={true} reit={false} platform={true}/>
              <HeadRow text={"Defi"} />
              <Row text={"Reduce third party risk"} traditional={false} reit={false} platform={true}/>
              <Row text={"Store off-line"} traditional={false} reit={false} platform={true}/>
              <Row text={"Extra income & capital gains"} traditional={false} reit={false} platform={true}/>
              <Row text={"Offline Storage of asset"} traditional={false} reit={false} platform={true}/>
              <Row text={"Lend, borrow, and control asset"} traditional={false} reit={false} platform={true}/> */}
            </tbody>
          </table>
        </div>

        <div className="justify-content-center align-items-center text-center">
          <button
            type="button"
            className="headerButtonBlue bg-[#2276c9]"
            onClick={() => {
              window.open("/comparison", "_blank");
            }}
          >
            View Full Page
          </button>
        </div>
      </section>
    </section>
  );
}
