import React from "react";
import { AiOutlineFileText } from "react-icons/ai";

export default function Docs() {
  return (
    <div className="docs py-32">
      <div className="flex justify-between xl:mx-80">
        <div className="grid">
          <p className="uppercase text-[#888a8b]">white papers</p>
          <p className="text-[#404040] text-[40px] font-bold">Documentation</p>
          <p className="text-[#404040] text-[40px] font-bold">Download</p>
        </div>
        <div className="mt-8">
          <AiOutlineFileText className="text-4xl text-center mx-auto text-[#2d83cb] mb-2" />
          <p className="text-[#404040] text-[20px] font-bold ">Whitepaper</p>
        </div>
        <div className="mt-8">
          <AiOutlineFileText className="text-4xl text-center mx-auto text-[#2d83cb] mb-2" />
          <p className="text-[#404040] text-[20px] font-bold">Tokenomics</p>
        </div>
        <div className="mt-8">
          <AiOutlineFileText className="text-4xl text-center mx-auto text-[#2d83cb] mb-2" />
          <p className="text-[#404040] text-[20px] font-bold">
            Executive Summary <br /> *Coming Soon
          </p>
        </div>
        <div className="mt-8">
          <AiOutlineFileText className="text-4xl text-center mx-auto text-[#2d83cb] mb-2" />
          <p className="text-[#404040] text-[20px] font-bold">
            ICO Summary <br /> *Coming Soon
          </p>
        </div>
      </div>
    </div>
  );
}
