import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Hero from "./components/Hero";
import Vision from "./components/Vision";
import About from "./components/About";
import Selection from "./components/Selection";
import Invest from "./components/Invest";
import Map from "./components/Map";
import Chart from "./components/Chart";
import Cta from "./components/Cta";
import Docs from "./components/Docs";
import "./css/style.css";

export default function IcoPage() {
  return (
    <>
      <Navbar />
      <Hero />
      <Vision />
      <About />
      <Selection />
      <Invest />
      {/* <Map /> */}
      <Chart />
      <Cta />
      <Docs />
      <Footer />
    </>
  );
}
