import React from "react";
import vision1 from "./../img/vision1.png";
import vision2 from "./../img/vision2.png";

export default function Invest() {
  return (
    <div className="bg-white text-center my-40 mx-40">
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
      <div className="text-[#2d83cb] text-[40px] font-bold">
        Two ways to invest
      </div>

      <div className="text-[#888a8b] text-[20px] w-1/2 mx-auto">
        <p className="py-3">
          You can either own a unique piece of property (PROPRT) secured on the
          blockchain or you can own a piece of the whole ecosystem through Real
          Estate (REST) tokens.
        </p>
      </div>
      <div className="grid grid-cols-2 mt-16 gap-8 mx-40">
        <div className="text-center">
          <div className="flex justify-center">
            <img src={vision1} className="text-center" />
          </div>
          <p className="text-black  text-[32px] font-semibold mt-4">PROPRT</p>
          <p className="py-3 text-[#888a8b]  text-[20px]">
            Is a Non-Fungible Property Token. An evolution of the NFT, built to
            ERC-721 Standard on the Ethereum Blockchain Network. You can verify
            your ownership, secure it in your own wallet or even take it
            offline.
          </p>
        </div>
        <div className="text-center">
          <div className="flex justify-center">
            <img src={vision2} className="text-center" />
          </div>
          <p className="text-black  text-[32px] font-semibold mt-4">REST</p>
          <p className="py-3 text-[#888a8b]  text-[20px]">
            REST tokens are standard ERC-20 tokens used to build and run the
            PROPRT.io platform. The tokens represent an ownership stake in the
            value of the entire ecosystem. Fees generated go to REST token
            holders. For more information refer to the tokenomics document
          </p>
        </div>
      </div>
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
    </div>
  );
}
