import React from "react";
import chart1 from "./../img/chart1.png";

export default function Chart() {
  return (
    <div className="bg-white text-center my-40 mx-40">
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
      <div className="text-[#2d83cb] text-[40px] font-bold">
        Simplified, Secured and Liquid Property Investing
      </div>
      <div className="text-black text-[40px] font-bold mt-12">
        Comparison Chart
      </div>
      <div className="flex justify-center mt-2">
        <img src={chart1} />
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          className=" font-semibold  text-[#fff] bg-[#2d83cb] py-3 px-3 rounded-full w-fit mt-12 hover:bg-[#43C1A8]"
        >
          View Full Comparison Table
        </button>
      </div>
      <hr className="text-[#888a8b] w-1/4 mx-auto my-12" />
    </div>
  );
}
