import React from "react";
import logo from "./img/navbarlogo.png";
import {
  FaDiscord,
  FaTwitter,
  FaFacebook,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsMedium } from "react-icons/bs";
import { default as ic_etherscan } from "./img/etherscan-logo-light-circle.svg";

export default function Footer() {
  return (
    <footer class="text-center text-lg-start bg-light text-[#0C2741]">
      <section class="pt-4">
        <div class="container text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <div className="flex justify-center sm:justify-start ">
                <img src={logo} alt="" className="mb-4" />
              </div>
              <p className="mb-4 hidden">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniamquis.
              </p>
              <div>
                <a
                  href="https://www.facebook.com/web3property"
                  class="me-4 text-reset"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/proprt_io" class="me-4 text-reset">
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  href="https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4"
                  class="me-4 text-reset"
                >
                  <i class="fab fa-medium"></i>
                </a>
                <a
                  href="https://www.instagram.com/proprt.io/"
                  class="me-4 text-reset"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/proprt-io/"
                  class="me-4 text-reset"
                >
                  <i class="fab fa-linkedin"></i>
                </a>
                <a href="https://github.com/PROPRT-io" class="me-4 text-reset">
                  <i class="fab fa-github"></i>
                </a>
              </div>
            </div>

            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <p className="mb-2">
                <a href="#!" class="text-reset">
                  Marketplace
                </a>
              </p>
              <p className="mb-2">
                <a href="#!" class="text-reset">
                  How it works
                </a>
              </p>
              <p className="mb-2">
                <a href="#!" class="text-reset">
                  Initial Coin Offering (ICO)
                </a>
              </p>
            </div>

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
             <a
              href="https://proprt.io/PROPRT.io-Whitepaperv0.3.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Whitepaper</p>
            </a>
            <a
              href="https://proprt.io/PROPRT.io-Tokenomics-v0.4.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Tokenomics</p>
            </a>

              <p className="mb-2">
                <a
                  href="https://www.canva.com/design/DAFZyQP9CM0/4FQXvADlqIhPnW5NrkE99A/view#1"
                  class="text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pitch Deck
                </a>
              </p>
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <p className="mb-2">
                <a href="#!" class="text-reset">
                  hello@proprt.io
                </a>
              </p>

              <p className="mb-2">
                <a href="#!" class="text-reset">
                  1 Corporate Court Bundall QLD Australia 4217
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div class="d-flex justify-content-center justify-content-lg-between p-4 container mx-auto">
        <div class="me-5 d-none d-lg-block">
          <span className="text-[#0C2741]">Terms and conditions</span>
        </div>

        <div>
          <p className="text-[#0C2741]">
            ©2023 - PROPRT.io | All right reserved
          </p>
        </div>
      </div>
    </footer>
    // <div></div>
  );
}
