import React, { useState, useEffect, useRef } from "react";
import illustration_female from "../img/illustration/undraw_female_avatar_w3jk-2.svg";
import illustration_male from "../img/illustration/undraw_male_avatar_323b-2.svg";
import ic_linkedin from "../img/icons/linkedin.svg";
import Slider from "react-slick";
import ellipseleft from "./img/ellipseleft.png"

import te_frank from "./img/team/frank.jpeg";
import te_rafi from "./img/team/rafi.jpeg";
import te_dennis from "./img/team/dennis.jpeg";
import te_karlo from "./img/team/karlo.jpeg";
import te_ron from "./img/team/ron.jpeg";
import te_ronnie from "./img/team/ronnie.jpeg";
import te_alan from "./img/team/alan.jpeg";
import te_aakash from "./img/team/aakash.jpg";
import te_stuart from "./img/team/stuart.jpeg";
import te_arve from "./img/team/arve.jpeg";
import te_adrian from "./img/team/adrian.jpeg";
import te_stefano from "./img/team/stefano.png";

export default function TeamMember() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 3,
    // speed: 500,
    variableWidth: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    focusOnSelect: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);


  return (
    <div className="my-40" style={{
      backgroundImage: `url(${ellipseleft})`,
      backgroundRepeat: "no-repeat",
      // backgroundSize: "100%",
      backgroundPosition: "left",
    }}>
      <section className="mt-5 pt-5 container mx-auto py-40" id="team">
        <h1
          className="text-4xl text-center text-[#0C2741] mb-20 font-semibold"
        >
          Team Members
        </h1>



        <Slider asNavFor={slider1} ref={(slider) => setSlider2(slider)} {...settings}>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_frank} alt="" className="rounded-lg" />
          </div>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_rafi} alt="" className="rounded-lg" />
          </div>
          {/* <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_dennis} alt="" className="rounded-lg" />
          </div> */}
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_stuart} alt="" className="rounded-lg" />
          </div>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_arve} alt="" className="rounded-lg" />
          </div>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_karlo} alt="" className="rounded-lg" />
          </div>
          {/* <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_ron} alt="" className="rounded-lg" />
          </div> */}
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_ronnie} alt="" className="rounded-lg" />
          </div>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_alan} alt="" className="rounded-lg" />
          </div>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_adrian} alt="" className="rounded-lg" />
          </div>
          <div style={{ width: 184 }} className="px-2 cursor-grab">
            <img src={te_stefano} alt="" className="rounded-lg" />
          </div>
        </Slider>
        <div className="mt-16">
          <Slider
            asNavFor={slider2}
            ref={(slider) => setSlider1(slider)}
          >
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-16 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Frank</h1>
              <p className="text-[#0C2741] text-based">Over 25 years’ experience as a property investor, property manager with a real estate licence and a BSc in Computer Science. Frank has a keen interest in solidity programming and web3 development.</p>
            </div>
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Rafi</h1>
              <p className="text-[#0C2741] text-based">Brings in depth coding and project skills to our team across range of software platforms and a particular interest in web3 development and NFT’s.</p>
            </div>
            {/* <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Dennis</h1>
              <p className="text-[#0C2741] text-based">Building on his years of experience as a designer, with “out-of-the-box” design and branding insights, combined with in depth knowledge in developing UX.</p>
            </div> */}
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Stuart</h1>
              <p className="text-[#0C2741] text-based">Stuart has a background in a information systems, he holds formal qualifications in IT and has over 20 years of real world industry experience.</p>
            </div>
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Arve</h1>
              <p className="text-[#0C2741] text-based">Arve is highly experienced Senior Software Engineer with demonstrated achievements working in the higher education industry. With a strong Full-stack development experience and engineering professional backed by a master degree.</p>
            </div>
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Karlo</h1>
              <p className="text-[#0C2741] text-based">Combining a BSc in Computer Science and experience in a range of IT projects including customer experience, SEO and back-end IT support. </p>
            </div>
            {/* <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Ron</h1>
              <p className="text-[#0C2741] text-based">With a BSc in Computer Science and experience as a full-stack developer across a range of languages and projects makes Ron critical to our Tech team.</p>
            </div> */}
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Ronnie</h1>
              <p className="text-[#0C2741] text-based">Over 25 years’ experience as a Real Estate Investor, Business broker and agent. Combined with strong sales and marketing skills makes Ronnie a key member of the team.</p>
            </div>
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Alan</h1>
              <p className="text-[#0C2741] text-based">Our legal team is spearheaded by our Corporate Lawyer with extensive experience in regulatory oversight, governance and bringing corporate projects to the market.</p>
            </div>
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Adrian</h1>
              <p className="text-[#0C2741] text-based">Corporate and Commercial Lawyer with a mixture of inhouse and private practice experience. His experience covers giving advise institutional and private clients in regard to their legal, trust, compliance, fund & product design and liability issues.</p>
            </div>
            <div style={{ width: 50 }} className="xl:px-80 text-center mt-8 cursor-grab">
              <h1 className="text-[#0C2741] font-bold text-xl">Stefano</h1>
              <p className="text-[#0C2741] text-based">Stefano is a experienced React Developer with a demonstrated history of working in the higher education industry. He has depth knowledge of JavaScript and React concepts, excellent front-end coding skills, and a good understanding of progressive web applications. </p>
            </div>
          </Slider>
        </div>
      </section>
    </div>
  );
}

const ProfileCard = ({ name, description, linkedin_url, profile_picture }) => {
  return (
    <div className="col-lg mb-4 row" style={{}}>
      <div
        className="col-4 text-center hover:cursor-pointer hover:scale-125 transform transition duration-500 ease-in-out"
        onClick={() => {
          window.open(linkedin_url, "_blank");
        }}
      >
        <img
          className="mb-2 shadow-lg mx-auto"
          src={profile_picture}
          style={{
            height: "6rem",
            marginTop: "5%",
            borderRadius: "50%",
            filter: "grayscale(100%)",
          }}
          alt=""
        />
        <p className="fw-bold mx-auto">{name}</p>
      </div>
      <p
        className="col text-justify"
        style={{
          fontSize: "13px",
          verticalAlign: "middle",
          display: "table-cell",
        }}
      >
        {description}
        <br />
        <a href={linkedin_url} rel="noreferrer" target="_blank">
          <img src={ic_linkedin} alt="" style={{ height: "1rem" }} />
        </a>
      </p>
    </div>
  );
};

const teams = [
  {
    name: "Frank",
    description:
      "Over 25 years’ experience as a property investor, property manager with a real estate licence and a BSc in Computer Science. Frank has a keen interest in solidity programming and web3 development.",
    linkedin_url: "https://www.linkedin.com/in/frankteunissenproprt/",
    profile_picture: te_frank,
  },
  {
    name: "Rafi",
    description:
      "Brings in depth coding and project skills to our team across range of software platforms and a particular interest in web3 development and NFT’s.",
    linkedin_url: "https://www.linkedin.com/in/wiratmoko/",
    profile_picture: te_rafi,
  },
  {
    name: "Stuart",
    description:
      "Stuart has a background in a information systems, he holds formal qualifications in IT and has over 20 years of real world industry experience. ",
    linkedin_url: "https://www.linkedin.com/in/bondstuart/",
    profile_picture: te_stuart,
  },
  {
    name: "Arve",
    description:
      "Arve is highly experienced Senior Software Engineer with demonstrated achievements working in the higher education industry. With a strong Full-stack development experience and engineering professional backed by a master degree.",
    linkedin_url: "https://www.linkedin.com/in/arvesolland/",
    profile_picture: te_arve,
  },
  {
    name: "Aakash",
    description:
      "A MSc in Computer Science and experience in product development, support and systems analysis makes Aakash a valuable team member.",
    linkedin_url: "https://www.linkedin.com/in/aakashjadhav/",
    profile_picture: te_aakash,
  },
  {
    name: "Karlo",
    description:
      "Combining a BSc in Computer Science and experience in a range of IT projects including customer experience, SEO and back-end IT support. ",
    linkedin_url: "https://www.linkedin.com/in/karlocamena/",
    profile_picture: te_karlo,
  },
  {
    name: "Ronnie",
    description:
      "Over 25 years’ experience as a Real Estate Investor, Business broker and agent. Combined with strong sales and marketing skills makes Ronnie a key member of the team.",
    linkedin_url: "https://www.linkedin.com/in/ronnie-hendriks-279a76231/",
    profile_picture: te_ronnie,
  },
  {
    name: "Ron",
    description:
      "With a BSc in Computer Science and experience as a full-stack developer across a range of languages and projects makes Ron critical to our Tech team.",
    linkedin_url: "https://www.linkedin.com/in/ron-michael-dionaldo-3b959846/",
    profile_picture: te_ron,
  },
  {
    name: "James",
    description:
      "Our legal team is spearheaded by our Corporate Lawyer with extensive experience in regulatory oversight, governance and bringing corporate projects to the market.",
    linkedin_url: "",
    profile_picture: illustration_male,
  },
  {
    name: "Alan",
    description:
      "Bringing in depth business analysis, ICT and system knowledge to the team. Over 25 years of experience in a range of large corporations and government agencies.",
    linkedin_url:
      "https://www.linkedin.com/in/alan-bergsma-cpd-pmp-msp-rmp-m-o-r-prince2-agile-a1b8256/",
    profile_picture: te_alan,
  },
  {
    name: "Dennis",
    description:
      "Building on his years of experience as a designer, with “out-of-the-box” design and branding insights, combined with in depth knowledge in developing UX.",
    linkedin_url: "https://www.linkedin.com/in/pixvox/",
    profile_picture: te_dennis,
  },
  {
    name: "Adrian",
    description:
      "Corporate and Commercial Lawyer with a mixture of inhouse and private practice experience. His experience covers giving advise institutional and private clients in regard to their legal, trust, compliance, fund & product design and liability issues.",
    linkedin_url: "https://www.linkedin.com/in/adrian-edwards-0a03b44/",
    profile_picture: te_adrian,
  },
];
