import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const items = [
    <div className="justify-center p-5 my-5 container w-9/12 mx-auto rounded-xl bg-light h-full shadow-lg">
        <div className="my-auto h-fit">
        <h1 className="h1 text-center text-xl font-bold">
            What is an NFT?
        </h1>
        <p className=" text-lg mt-3 text-center">
            A Non-Fungible Token (NFT) is a unique digital asset or "digital collectible" that can be owned, transferred or sold.
        </p>
        </div>
    </div>,
    <div className="justify-center p-5 my-5 container w-9/12 mx-auto rounded-xl bg-light h-full shadow-lg">
        <div className="my-auto h-fit">
        <h1 className="h1 text-center text-xl font-bold">
            What is the difference between PROPRT.io -vs- PROPRT Tokens?
        </h1>
        <p className=" text-lg mt-3 text-center">
            PROPRT.io is a reference to the platform. However, PROPRT tokens are the Ethereum tokens represent a unique piece of a specific property.
        </p>
        </div>
    </div>,
    <div className="justify-center p-5 my-5 container w-9/12 mx-auto rounded-xl bg-light h-full shadow-lg">
        <div className="my-auto h-fit">
        <h1 className="h1 text-center text-xl font-bold">
            Do I need a web3 wallet?
        </h1>
        <p className=" text-lg mt-3 text-center">
            Not if you want to access the benefits and hold your asset. If you wish to list or sell on other platforms, we will provide a streamlined process to access your web3 wallet.
        </p>
        </div>
    </div>,
    <div className="justify-center p-5 my-5 container w-9/12 mx-auto rounded-xl bg-light h-full shadow-lg">
        <div className="my-auto h-fit">
        <h1 className="h1 text-center text-xl font-bold">
            Aren't NFTs bad for the environment?
        </h1>
        <p className=" text-lg mt-3 text-center">
            No. Since Ethereum moved to Proof Of Stake (POS), NFTs are environmentally friendly. Visit the ethereum.org website for more information.
        </p>
        </div>
    </div>,
];

export default function FaqCarousel() {
  return (
    <>

    <section className="container"  style={{ marginTop: "7%", marginBottom: "7%" }}>
    <div className="text-center">
          <h1
            style={{
              color: "#2276C9",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
            className="text-3xl"
          >
            Frequently Asked Questions
          </h1>
        </div>
    <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
    />
    </section>
   
    </>
  )
}
